@import "../../../def";

.trigger {
  display: inline-flex;
  vertical-align: middle;
  position: relative;

  &[data-interactive="clickable"],
  &[data-interactive="hoverable"] {
    padding: 2px 5px;
    border-radius: 3px;

    &:hover {
      background: var(--color-element-hover)
    }

    &[data-negate-interactive-padding="left"] {
      left: -5px;
    }

    &[data-negate-interactive-padding="right"] {
      right: -5px;
    }
  }

  &[data-interactive="clickable"] {
    color: var(--color-primary);

    &,
    * {
      cursor: pointer;
    }
  }
}

.tooltip {
  position: absolute;
  pointer-events: none;
  transform: translate(-50%);
  z-index: 99999;
  user-select: none;
  transition: opacity 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
  max-width: 320px;

  &.visible.delay {
    transition-delay: 350ms;
  }

  &:not(.visible) {
    opacity: 0;
    transition-delay: 0s;
  }

  &[data-position="top"] {
    transform: translate(-50%, calc(-100% - 12px));
  }

  &[data-position="bottom"] {
    transform: translate(-50%, 12px);
  }

  &[data-position="right"] {
    transform: translate(12px, -50%);
  }

  &[data-position="left"] {
    transform: translate(calc(-100% - 12px), -50%);
  }

  .text {
    position: relative;
    // Colors are not affected by theme.
    // In dark mode, black on white turned out not very readable. 
    background-color: #424242;
    color: #fff;
    font-weight: normal;
    text-align: left;
    font-size: 13px;
    padding: 8px 12px;
    border-radius: 3px;

    > div {
      min-height: 1em; // Fixes blank rows.
    }
  }
}