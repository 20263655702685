@import "../../../def";
@import "def";

.button {
  white-space: nowrap;
  margin: 0;
  font-weight: normal;
  font-weight: var(--font-weight-default);
  padding: 8px 16px;
  font-size: 15px;
  border-radius: var(--border-radius-button);
  height: $button-height;
  box-sizing: border-box;
  outline: none !important;
  position: relative;
  display: inline-flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  user-select: none;

  &::before {
    transition: background-color $button-transition-dur $button-transition-ease;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    pointer-events: none;
  }

  &[data-size="compact"] {
    height: $button-height-compact;

    &,
    &::before,
    &::after {
      border-radius: #{$button-height-compact / 2};
    }
  }

  &.iconOnly {
    min-width: 28px;
    max-width: 28px;
    height: 28px;
    box-sizing: border-box;
    padding: 0 !important;

    &,
    &::before,
    &::after {
      //  In case the button is stretched width-wise, the border-radius cannot be 50%.
      border-radius: calc(28px / 2);
    }

    &[data-icon-size="medium"] {
      min-width: 32px;
      max-width: 32px;
      height: 32px;

      &,
      &::before,
      &::after {
        border-radius: calc(32px / 2);
      }

      .icon {
        font-size: 17px;
      }
    }

    &[data-icon-size="large"] {
      min-width: 36px;
      max-width: 36px;
      height: 36px;

      &,
      &::before,
      &::after {
        border-radius: calc(36px / 2);
      }

      .icon {
        font-size: 20px;
      }
    }

    &[data-icon-size="xlarge"] {
      min-width: 48px;
      max-width: 48px;
      height: 48px;

      &,
      &::before,
      &::after {
        border-radius: calc(48px / 2);
      }

      .icon {
        font-size: 24px;
      }
    }

    .icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin: 0 !important;
    }
  }

  //  This is the fake outline.
  &::after {
    position: absolute;
    content: " ";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(var(--color-primary-rgb), 0.35);
    box-sizing: border-box;
    opacity: 0;
    pointer-events: none;
    transition: opacity 75ms ease-out;
  }

  &::before,
  &::after {
    border-radius: var(--border-radius-button);
  }

  &:focus {
    &::after {
      opacity: 1;
    }
  }

  &.disabled {
    opacity: $button-disabled-opacity;
    cursor: not-allowed;
  }

  &:not(.disabled):hover {
    cursor: pointer;
  }

  &:not(.disabled) {

    &:active,
    &.active {
      &::before {
        transition: background-color 25ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }
  }

  .text {
    padding: 4px 4px 4px 0;
  }

  .icon,
  .taskIndicator {
    font-size: 18px;
  }

  .icon:not(svg) {
    font-size: 13px;
  }

  svg.icon {
    width: 16px;
    height: 16px;
  }

  &[data-weight="normal"],
  &[data-weight="dimmed"],
  &[data-weight="danger"],
  &[data-weight="simple"] {
    border: none;
    background-color: var(--color-button-background-normal);
    padding: 4px var(--padding-button-normal-horizontal);

    &:not(.disabled) {

      &:active,
      &.active {
        &::before {
          background-color: var(--color-element-active);
        }
      }

      &:hover {
        &:not(:active):not(.active) {
          &::before {
            background-color: var(--color-element-hover);
          }
        }
      }
    }
  }

  &[data-weight="primary"] {
    background-color: var(--color-primary);
    border: none;

    &,
    .icon {
      color: var(--color-background-2) !important;
      fill: var(--color-background-2);
    }

    .taskIndicator [data-spinner] {
      border-color: var(--color-background-2);
    }
  }

  &[data-weight="normal"] {
    &,
    .icon {
      color: var(--color-button-normal);
      fill: var(--color-button-normal);
    }
  
    &:not(.iconOnly) {
      .icon {
        color: var(--color-accent);
      }
    }

    .taskIndicator [data-spinner] {
      border-color: var(--color-button-normal);
    }
  }

  &[data-weight="simple"] {
    background: transparent;
    border: none;

    &,
    .icon {
      color: var(--color-button-simple);
      fill: var(--color-button-simple);
    }
  
    &:not(.iconOnly) {
      .icon {
        color: var(--color-accent);
      }
    }

    .taskIndicator [data-spinner] {
      border-color: var(--color-button-simple);
    }
  }

  &[data-weight="danger"] {
    background: transparent;
    border: none;

    &,
    .icon {
      color: var(--color-danger);
      fill: var(--color-danger);
    }

    .taskIndicator [data-spinner] {
      border-color: var(--color-danger);
    }
  }

  &[data-weight="dimmed"] {
    background: transparent;
    border: none;

    &,
    .icon,
    .task-indicator {
      color: var(--color-text-3);
      fill: var(--color-text-3);
    }
  }

  &[data-weight="accent"] {
    background: transparent;
    border: none;

    &,
    .icon,
    .task-indicator {
      color: var(--color-accent);
      fill: var(--color-accent);
    }
  }

  &[data-weight="danger"] {
    background-color: var(--color-button-background-danger);
  }

  &[data-weight="dimmed"] {
    background-color: var(--color-button-background-dimmed);
  }
}

.badge {
  $size: 6px;

  width: $size;
  height: $size;

  border-radius: 50%;
  background: var(--color-primary);
  margin-left: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-10px);
  right: 16px;

  &.offset {
    right: 0;
  }
}